

import image1 from '../assets/home.jpeg'
import image2 from '../assets/mla.jpeg'
import image3 from '../assets/reception.jpeg'
import image4 from '../assets/icu.jpeg'
import image5 from '../assets/hospital_front.jpeg'
import image6 from '../assets/main.jpeg'
import image7 from '../assets/ambulance.jpeg'
import image8 from '../assets/function.jpeg'
export const carouselData = [
    {
      img: image1,
      title: "Discover A Brand Luxurious Hotel",
      subtitle: "luxury living",
      btn1: "Our Room",
      btn2: "Book Room",
    },
    {
      img: image2,
      title: "Discover A Brand Luxurious Hotel",
      subtitle: "luxury living",
      btn1: "Our Room",
      btn2: "Book Room",
    },
    {
        img: image3,
        title: "Discover A Brand Luxurious Hotel",
        subtitle: "luxury living",
        btn1: "Our Room",
        btn2: "Book Room",
      },
      {
        img: image4,
        title: "Discover A Brand Luxurious Hotel",
        subtitle: "luxury living",
        btn1: "Our Room",
        btn2: "Book Room",
      },
      {
        img: image5,
        title: "Discover A Brand Luxurious Hotel",
        subtitle: "luxury living",
        btn1: "Our Room",
        btn2: "Book Room",
      },
      {
        img: image6,
        title: "Discover A Brand Luxurious Hotel",
        subtitle: "luxury living",
        btn1: "Our Room",
        btn2: "Book Room",
      },
      {
        img: image7,
        title: "Discover A Brand Luxurious Hotel",
        subtitle: "luxury living",
        btn1: "Our Room",
        btn2: "Book Room",
      },
      {
        img: image8,
        title: "Discover A Brand Luxurious Hotel",
        subtitle: "luxury living",
        btn1: "Our Room",
        btn2: "Book Room",
      },
  ];