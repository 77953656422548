import React from 'react'
import about from '../assets/about.png'
import Hero from '../components/pages/Hero'
import Biography from '../components/pages/Biography'
import whoever from '../assets/whoweare.png'

const About = () => {
  return (
    <>
    <Hero
        title={"Learn More About Us | Sea be Cure Multi Super Speciality Hospital "}
        imageUrl={about}
      />
      <Biography imageUrl={whoever} />
    </>
  )
}

export default About
