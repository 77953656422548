import React from "react";
import { Link } from "react-router-dom";
import { FaLocationArrow, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import logo from '../../assets/logo2-removebg-preview.png'

const Footer = () => {
  const hours = [
    {
      id: 1,
      day: "Open ",
      time: "24 X 7 Hours",
    },
    // {
    //   id: 2,
    //   day: "Tuesday",
    //   time: "12:00 PM - 12:00 AM",
    // },
    // {
    //   id: 3,
    //   day: "Wednesday",
    //   time: "10:00 AM - 10:00 PM",
    // },
    // {
    //   id: 4,
    //   day: "Thursday",
    //   time: "9:00 AM - 9:00 PM",
    // },
    // {
    //   id: 5,
    //   day: "Monday",
    //   time: "3:00 PM - 9:00 PM",
    // },
    // {
    //   id: 6,
    //   day: "Saturday",
    //   time: "9:00 AM - 3:00 PM",
    // },
  ];

  return (
    <>
      <footer className={"container"}>
        <hr />
        <div className="content">
          <div>
            <img src={logo} alt="logo" width='20%' className="logo-img"/>
          </div>
          <div>
            <h4>Quick Links</h4>
            <ul>
              <Link to={"/"}>Home</Link>
              <Link to={"/appoinment"}>Appointment</Link>
              <Link to={"/aboutus"}>About</Link>
              <Link to={"/contact"}>Contact</Link>
            </ul>
          </div>
          <div>
            <h4>Hours</h4>
            <ul style={{listStyle:'none'}}>
              {hours.map((element) => (
                <li key={element.id}>
                  <span>{element.day}</span>
                  <span>{element.time}</span>
                </li>
              ))}
            </ul>
          </div>
          <div style={{display:"flex",flexDirection:"column",
            justifyContent:"start",alignItems:"start",textAlign:"left"
          }}>
            <h4>Contact</h4>
            <div>
              <FaPhone />
              <span> 7810926282 / 6295294389 (WhatsApp)</span>
            </div>
            <div>
              
              <div style={{display:'flex',flexDirection:'column'}}>
                <div >
                  <MdEmail />
              <span > customercare@seabecurehospital.com</span>
              </div>
              <div>
              <MdEmail/>
              <span> administrativehead@seabecurehospital.com</span>
              </div>
              </div>
            </div>
            <div>
              <FaLocationArrow />
              <span>R S Road , NH 60 , Haripur Bazar , Paschim Bardhaman , 713378 , West Bengal</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
