import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, Checkbox, FormControl, FormLabel, Input, Select, Textarea } from "@chakra-ui/react";

const AppointmentForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nic, setNic] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [department, setDepartment] = useState("Pediatrics");
  const [doctorFirstName, setDoctorFirstName] = useState("");
  const [doctorLastName, setDoctorLastName] = useState("");
  const [address, setAddress] = useState("");
  const [hasVisited, setHasVisited] = useState(false);
  const [doctors, setDoctors] = useState([]);

  const departmentsArray = [
    "General Medicine",
    "Urology",
    "GENERAL/LAPAROSCOPIC SURGERY",
    "GYNAEOCOLOGY",
    "PAEDIATRIC",
    "NEPHROLOGY",
    "PLASTIC SURGERY",
    "RADIOLOGY",
    "ORTHOPAEDIC",
    "PHYSIOTHERAPIST",
    "ENT",
    "CARDIOLOGY",
    "Oral & Dental Surgeon",
    "Pain Clinic"

  ];

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const { data } = await axios.get(
          "http://localhost:4000/api/v1/user/doctors",
          { withCredentials: true }
        );
        setDoctors(data.doctors);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDoctors();
  }, []);

  const handleAppointment = async (e) => {
    e.preventDefault();
    try {
      const hasVisitedBool = Boolean(hasVisited);
      const { data } = await axios.post(
        "http://localhost:4000/api/v1/appointment/post",
        {
          firstName,
          lastName,
          email,
          phone,
          nic,
          dob,
          gender,
          appointment_date: appointmentDate,
          department,
          doctor_firstName: doctorFirstName,
          doctor_lastName: doctorLastName,
          hasVisited: hasVisitedBool,
          address,
        },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success(data.message);
      resetForm();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setNic("");
    setDob("");
    setGender("");
    setAppointmentDate("");
    setDepartment("Pediatrics");
    setDoctorFirstName("");
    setDoctorLastName("");
    setAddress("");
    setHasVisited(false);
  };

  return (
    <Box className="container form-component appointment-form">
      <h2>Appointment</h2>
      <form onSubmit={handleAppointment}>
        <div>
          <Input
          
          
          mb={8}
          variant="flushed"
          bg="white"
          height="49px"
          border="1px solid gray"
          borderRadius="6px"
          p={4}
          flex={{ base: "1 0 100%", md: "1 0 48%" }}
             
            type="text"
            placeholder="First Name"
            value={firstName}
            w='full'
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Input
             p={4}
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div>
          <Input
             mb={8}
             variant="flushed"
             bg="white"
             height="49px"
             border="1px solid gray"
             borderRadius="6px"
             p={4}
             flex={{ base: "1 0 100%", md: "1 0 48%" }}
                
               type="text"
               
            
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
              mb={8}
              variant="flushed"
              bg="white"
              height="49px"
              border="1px solid gray"
              borderRadius="6px"
              p={4}
              flex={{ base: "1 0 100%", md: "1 0 48%" }}
                 
                
            type="number"
            placeholder="Mobile Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div>
          <Input
           mb={8}
           variant="flushed"
           bg="white"
           height="49px"
           border="1px solid gray"
           borderRadius="6px"
           p={4}
           flex={{ base: "1 0 100%", md: "1 0 48%" }}
              
            
            type="number"
            placeholder="NIC"
            value={nic}
            onChange={(e) => setNic(e.target.value)}
          />
          <Input
              mb={8}
              variant="flushed"
              bg="white"
              height="fit-content"
              border="1px solid gray"
              borderRadius="6px"
              p={4}
              flex={{ base: "1 0 100%", md: "1 0 48%" }}
                 
                
            type="date"
            placeholder="Date of Birth"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
        </div>
        <div>
          <Select
              mb={8}
              variant="flushed"
              bg="white"
              height="fit-content"
              border="1px solid gray"
              borderRadius="6px"
              p={4}
              flex={{ base: "1 0 100%", md: "1 0 48%" }}
                 
               
            placeholder="Select Gender"
            value={gender}
            w='full'
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
          <Input
              mb={8}
              variant="flushed"
              bg="white"
              height="fit-content"
              border="1px solid gray"
              borderRadius="6px"
              p={4}
              flex={{ base: "1 0 100%", md: "1 0 48%" }}
                 
               
            type="date"
            w='full'
            placeholder="Appointment Date"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
          />
        </div>
        <div>
          <Select
              mb={8}
              variant="flushed"
              bg="white"
              height="49px"
              border="1px solid gray"
              borderRadius="6px"
              p={4}
              flex={{ base: "1 0 100%", md: "1 0 48%" }}
                 
               
            value={department}
            onChange={(e) => {
              setDepartment(e.target.value);
              setDoctorFirstName("");
              setDoctorLastName("");
            }}
          >
            {departmentsArray.map((depart, index) => (
              <option value={depart} key={index}>
                {depart}
              </option>
            ))}
          </Select>
          <Select  mb={8}
          variant="flushed"
          bg="white"
          height="49px"
          border="1px solid gray"
          borderRadius="6px"
          p={4}
          flex={{ base: "1 0 100%", md: "1 0 48%" }}
             
            type="text"
            placeholder="First Name"
            value={`${doctorFirstName} ${doctorLastName}`}
            
            onChange={(e) => {
              const [firstName, lastName] = e.target.value.split(" ");
              setDoctorFirstName(firstName);
              setDoctorLastName(lastName);
            }}
            disabled={!department}
          >
            <option value="">Select Doctor</option>
            {doctors
              .filter((doctor) => doctor.doctorDepartment === department)
              .map((doctor, index) => (
                <option
                  value={`${doctor.firstName} ${doctor.lastName}`}
                  key={index}
                >
                  {doctor.firstName} {doctor.lastName}
                </option>
              ))}
          </Select>
        </div>
        <Textarea
          rows="10"
          p={4}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder="Address"
        />
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="visited" mb="0">Have you visited before?</FormLabel>
          <Checkbox
             p={4}
            id="visited"
            isChecked={hasVisited}
            onChange={(e) => setHasVisited(e.target.checked)}
            style={{ flex: "none", width: "25px" }}
          />
        </FormControl>
        <Button  margin="auto" colorScheme="teal" boxShadow='dark-lg'>GET APPOINTMENT</Button>
       
      </form>
    </Box>
    
  );
};

export default AppointmentForm;