import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Container, Heading, Image, Text } from "@chakra-ui/react";
import pedia from "../../assets/departments/pedia.jpg";
import ortho from "../../assets/departments/ortho.jpg";
import cardio from "../../assets/departments/cardio.jpg";
import neuro from "../../assets/departments/neuro.jpg";
import onco from "../../assets/departments/onco.jpg";
import medicine from '../../assets/general_medicine.jpeg'
import radio from "../../assets/departments/radio.jpg";
import therapy from "../../assets/departments/therapy.jpg";
import derma from "../../assets/departments/derma.jpg";
import ent from "../../assets/departments/ent.jpg";
import urology from '../../assets/departments/urology.jpeg'
import surgery from '../../assets/departments/general-surgery.jpeg'
import gyno from '../../assets/departments/gynology.jpeg'
import nephro from '../../assets/departments/nephrology.jpeg'
import plastic from '../../assets/departments/plastic surgery.jpeg'
import pulmo from '../../assets/departments/pulmonology.jpeg'
import dental from '../../assets/dental2.jpeg'
import pain from '../../assets/pain_clinic.jpeg'

const Departments = () => {
  const departmentsArray = [
    {name:"General Medicine",imageUrl:medicine},
    {name:"Urology",imageUrl:urology},
    {name:"General/Laparoscopic Surgery",imageUrl:surgery},
    {name:"Gynaeocology",imageUrl:gyno},
    {name:"Nephrology",imageUrl:nephro},
    {name:"Plastic Surgery",imageUrl:plastic},
    {name:"Orthopaedic",imageUrl:ortho},
    // {name:"Pulmonology",imageUrl:pulmo},
    { name: "Pediatrics", imageUrl: pedia },
    { name: "Orthopedics", imageUrl: ortho },
    { name: "Cardiology", imageUrl: cardio },
    // { name: "Neurology", imageUrl: neuro },
    
    { name: "Radiology", imageUrl: radio },
    { name: "Physical Therapy", imageUrl: therapy },
    
    { name: "ENT", imageUrl: ent },
    { name: "Oral & Dental Surgeon", imageUrl: dental },
    { name: "Pain Clinic", imageUrl: pain },
  ];

  const responsive = {
    extraLarge: { breakpoint: { max: 3000, min: 1324 }, items: 4, slidesToSlide: 1 },
    large: { breakpoint: { max: 1324, min: 1005 }, items: 3, slidesToSlide: 1 },
    medium: { breakpoint: { max: 1005, min: 700 }, items: 2, slidesToSlide: 1 },
    small: { breakpoint: { max: 700, min: 0 }, items: 1, slidesToSlide: 1 },
  };

  return (
    <Box bg="gray.100" py="20" px='40'>
      <Container maxW="container.xl" className="departments">
        <Heading as="h2" color="black" mb="4">
          Departments
        </Heading>
        <Carousel
          responsive={responsive}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {departmentsArray.map((depart, index) => (
            <Box key={index} className="card" textAlign="center" ml={14} borderRadius='12px'>
              <Text fontSize="xl" fontWeight="bold" bg='#e5e5e5' w='223px' h='fit-content' textAlign='center' p={10} borderRadius='6px' alignItems='center' mb="10">
                {depart.name}
              </Text>
              <Image src={depart.imageUrl} h='fit-content' alt="Department" borderRadius='12px' />
            </Box>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};

export default Departments;
