import React,{useState} from 'react'
import { Card, Image, Stack, CardBody, Heading, Text, CardFooter, Button, Box,Flex ,FormControl,FormLabel,Input} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../../assets/signin.png'


import { Navigate } from 'react-router-dom'
//import { CardHeader } from 'react-bootstrap'

const Login = () => {
//   const dispatch = useDispatch();
   const [email,setEmail]=useState('');
   const [password,setPassword]=useState('');

    const navigate=useNavigate();
    const handleLogin = async(e) => {
      e.preventDefault();
    //   try {
    //       const resultAction = await dispatch(loginAsync(credentials));
    //       const {payload}=resultAction;
    //       if (loginAsync.fulfilled.match(resultAction)) {
    //         // Here, payload should contain the user data including token
    //   console.log('Logged in successfully:', payload);

    //   // You can store the token in localStorage or sessionStorage for persistence
    //   localStorage.setItem('customerId', payload.customerId);
    //           navigate('/'); // Navigate to homepage on successful login
    //       }
    //   } catch (error) {
    //       console.error('Failed to login:', error);
    //   }
       
    };
  return (
    <Box display='flex' alignItems="center" justifyContent="center" height="400px" bg='#97ded9' >
      
      <Card
        direction={{ base: 'column', md: 'row' }}
        overflow='hidden'
        variant='outline'
        alignItems="center" justifyContent="space-between"
        w='45%'
        h='350px'
        bg='white'
        border='2px solid #3dc3b6'
        borderRadius='12px'
       p={13}
        
        
      >
        
        <Image
         
          style={{width:"50%"}}
          display={{lg:'block',md:'none',sm:'none'}}
          
          h='320'
          borderRadius='12px'
          src={logo}
          alt='logo'
        />

        <Stack>
            {/* <CardHeader mb='20'>
                <Text>Login</Text>
            </CardHeader> */}
          <CardBody>
          <Box flex="1 0 48%">
              <Box mb={10}>
                <Heading as="h3" fontSize="xl" mb={15} style={{color:'teal'}}>
                  Login
                </Heading>
                <form>
                  <Flex flexWrap="wrap" flexDirection='column' justifyContent="space-between" mb={3}>
                    <Box display='flex' gap={2} justifyContent='space-evenly' alignItems='center'>
                        <FormLabel mr={4}>Email</FormLabel>
                        <Input
                      type="text"
                      placeholder="Your email"
                      mb={8}
                      variant="flushed"
                      bg="white"
                      w='100%'
                      height="49px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      flex={{ base: "1 0 100%", md: "1 0 48%" }}
                    />
                    </Box>
                    <Box display='flex' gap={2} justifyContent='space-evenly' alignItems='center'>
                        <FormLabel mr={4}>
                            Password
                        </FormLabel>
                        <Input
                      type="password"
                      placeholder="Your Password"
                      mb={8}
                      w='80%'
                      variant="flushed"
                      bg="white"
                      height="49px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      flex={{ base: "1 0 100%", md: "1 0 48%" }}
                    />
                    </Box>
                    
                    {/* <Input
                      type="text"
                      placeholder="Subject"
                      mb={8}
                      variant="flushed"
                      bg="white"
                      height="49px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      flex="1 0 100%"
                    />
                    <Textarea
                      placeholder="Leave a message here"
                      mb={8}
                      variant="flushed"
                      bg="white"
                      height="100px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      style={{ resize: "none" }}
                      flex="1 0 100%"
                    /> */}
                    <Button
                      color="white"
                      bg="teal"
                      _hover={{ bg: "teal.600" }}
                      size="lg"
                      w="100%"
                      borderRadius='12px'
                      border='none'
                      height="49px"
                      type="submit"
                    >
                      Login
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Box>
          </CardBody>
         

          <CardFooter alignItems="center" justifyContent="center">
            <Link to='/signup' style={{color:'teal'}}><b>If not Login?Signup</b></Link>
          </CardFooter>
        </Stack>
      </Card>
      </Box>
    
  )
}

export default Login
