import React, { useRef } from "react";
import { Box, Button, Container, Image, Link,Flex,Heading,Text } from "@chakra-ui/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { carouselData } from "../components/data";
import { useNavigate } from "react-router-dom";

export default function Carousel2() {
  const navigate=useNavigate();
  const responsive = {
    extraLarge: { breakpoint: { max: 3000, min: 1324 }, items: 1, slidesToSlide: 1 },
    large: { breakpoint: { max: 1324, min: 1005 }, items: 1, slidesToSlide: 1 },
    medium: { breakpoint: { max: 1005, min: 700 }, items: 1, slidesToSlide: 1 },
    small: { breakpoint: { max: 700, min: 0 }, items: 1, slidesToSlide: 1 },
  };

 

  return (
    <Box bg="gray.100"  w='full' >
    <Container maxW="container.xl"  >
      {/* <Heading as="h2" color="black" mb="4">
        Departments
      </Heading> */}
      <Carousel
        responsive={responsive}
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {carouselData.map((depart, index) => (
          <Box key={index} className="card" textAlign="center">
            {/* <Text fontSize="xl" fontWeight="bold" bg='#e5e5e5' w='223px' h='49px' textAlign='center' p={10} borderRadius='6px' alignItems='center' mb="2">
              {depart.name}
            </Text> */}
            <Image src={depart.img} alt="Department" h='fit-content' w='fit-content' opacity='0.9' borderRadius='1px' />
          </Box>
        ))}
      </Carousel>
    </Container>
  </Box>
  );
}
