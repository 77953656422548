import React from 'react'
import Hero from '../components/pages/Hero'
import AppointmentForm from '../components/pages/Appoinment'
import signin from '../assets/signin.png'

const Appoinment = () => {
  return (
    <>
    <Hero
        title={"Schedule Your Appointment | Sea be Cure Multi Super Speciality Hospital "}
        imageUrl={signin}
      />
      <AppointmentForm/>
    </>
  )
}

export default Appoinment
