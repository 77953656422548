import logo from './logo.svg';
import { useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './modules/Home';
import {Routes,Route,Router} from 'react-router-dom'
import Appoinment from './modules/Appoinment';
import About from './modules/About';
import Footer from './components/pages/Footer';
import Contact from './modules/Contact';
import Login from './modules/auth/Login/Login';
import SignUp from './modules/auth/Signup/Signup';
import ScrollToTop from './modules/ScrollToTop';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
     
      <Navbar/>
     <ScrollToTop/> 
      <Routes>
      
        <Route path='/' element={<Home/>}/>
        <Route path='/appoinment' element={<Appoinment/>}/>
        <Route path='/aboutus' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<SignUp/>}/>
      </Routes>
      
      <Footer/>
    </div>
  );
}

export default App;
