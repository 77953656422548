import React from "react";
import {
  Heading,
  Box,
  Container,
  Text,
  Flex,
  Button,
  Input,
  Textarea,
  SimpleGrid,
} from "@chakra-ui/react";
import { GoogleMap, LoadScript,Marker } from "@react-google-maps/api";

export default function Contact() {
  const apiKey="AIzaSyDjm5eln6uplj5KMemxhwGCddECgN-O1kY"
  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  const center = {
    lat:  23.6782336,
    lng: 87.1890519,
  };
  return (
    <>
      <Heading as="h1" textAlign="center" mt={10} mb={5} color="black">
        Contact Us
      </Heading>

      <Box py={10}>
        <Container maxW="container.xl">
          <SimpleGrid columns={3} justifyContent="space-between" mb={10}>
            {/* Your contact information can be added here */}
          </SimpleGrid>

          <Flex flexWrap="wrap" justifyContent="space-between" gap={20} p={20}>
            {/* Google Maps iframe */}
            <Box flex="1 0 48%" mb={10}>
              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                width="100%"
                height="350"
                style={{ border: "0", minHeight: "350px" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe> */}
               <LoadScript 
                 libraries={["places"]}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={30}
      >
        {/* Your markers, overlays, etc. */}
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
            </Box>

            {/* Contact form */}
            <Box flex="1 0 48%">
              <Box mb={10}>
                <Heading as="h3" fontSize="xl" mb={15}>
                  Send Us a Message
                </Heading>
                <form>
                  <Flex flexWrap="wrap" justifyContent="space-between" mb={3}>
                    <Input
                      type="text"
                      placeholder="Your Name"
                      mb={8}
                      variant="flushed"
                      bg="white"
                      w='45%'
                      height="49px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      flex={{ base: "1 0 100%", md: "1 0 48%" }}
                    />
                    <Input
                      type="email"
                      placeholder="Your Email"
                      mb={8}
                      w='45%'
                      variant="flushed"
                      bg="white"
                      height="49px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      flex={{ base: "1 0 100%", md: "1 0 48%" }}
                    />
                    <Input
                      type="text"
                      placeholder="Subject"
                      mb={8}
                      variant="flushed"
                      bg="white"
                      height="49px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      flex="1 0 100%"
                    />
                    <Textarea
                      placeholder="Leave a message here"
                      mb={8}
                      variant="flushed"
                      bg="white"
                      height="100px"
                      border="1px solid gray"
                      borderRadius="6px"
                      p={4}
                      style={{ resize: "none" }}
                      flex="1 0 100%"
                    />
                    <Button
                      color="white"
                      bg="teal"
                      _hover={{ bg: "teal.600" }}
                      size="lg"
                      w="100%"
                      height="49px"
                      type="submit"
                    >
                      Send Message
                    </Button>
                  </Flex>
                </form>
              </Box>
            </Box>

            {/* Google Maps place link */}
            {/* <Box flex="1 0 100%" mb={10}>
              <iframe
                src="https://www.google.com/maps/place/23%C2%B040'41.6%22N+87%C2%B011'20.6%22E/@23.6782336,87.186477,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.6782336!4d87.1890519?hl=en&entry=ttu"
                width="100%"
                height="350"
                style={{ border: "0", minHeight: "350px" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Box> */}
          </Flex>
        </Container>
      </Box>
    </>
  );
}
