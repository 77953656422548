import React from "react";
import { Box, Container, Image, Text } from "@chakra-ui/react";

const Biography = ({ imageUrl }) => {
  return (
    <Box bg="gray.100" py="20" px='40' p={4}>
      <Container maxW="container.xl" className="biography" >
        <Box display="flex" justifyContent="center" alignItems="center" p={10}>
          <Image src={imageUrl} alt="whoweare" style={{width:'500px'}}/>
        </Box>
       
        <Box maxW="xl" mt="8" textAlign='left' padding='10px' background=' #f8f7ff'
    border= '2px solid #e9e6ff' borderRadius='6px' display='grid' placeItems='left' >
          <Text fontSize="2xl" fontWeight="bold">Biography</Text>
          <Text fontSize="xl" fontWeight="bold">Who We Are</Text>
          <Text fontSize="15px" mt="2">
          Sea be Cure Multi Super Speciality Hospital  stands as a beacon of modern healthcare innovation and compassionate patient care. Established in 2023, our hospital was founded with a singular vision: to redefine the healthcare experience by blending cutting-edge medical technology with personalized, patient-centric care.

From its inception, Sea be Cure Multi Super Speciality Hospital  has been dedicated to fostering a healing environment where every patient feels valued, supported, and empowered on their journey to wellness. Our state-of-the-art facilities boast the latest advancements in medical equipment, ensuring accurate diagnoses and effective treatments across a wide spectrum of medical specialties.

At the core of HavenCare's philosophy is our commitment to excellence in healthcare delivery. Our team of highly skilled medical professionals, including renowned specialists and compassionate nurses, are united in their mission to provide the highest standard of medical care. Whether addressing complex surgical procedures, chronic conditions, or routine health check-ups, every member of our staff is dedicated to delivering personalized attention and empathy to each patient.

Beyond clinical expertise, Sea be Cure Multi Super Speciality Hospital  prides itself on fostering a warm and welcoming atmosphere. Our spacious and meticulously designed patient rooms offer comfort and privacy, while our serene public areas provide a peaceful retreat for patients and their families during challenging times.

Complementing our clinical services, Sea be Cure Multi Super Speciality Hospital  places a strong emphasis on community engagement and patient education. Through outreach programs, health fairs, and educational seminars, we strive to empower individuals with the knowledge and resources needed to make informed decisions about their health.

As we look to the future, Sea be Cure Multi Super Speciality Hospital  remains committed to pioneering advancements in healthcare and expanding our services to meet the evolving needs of our community. Whether you are seeking routine care or specialized treatment, you can trust Sea be Cure Multi Super Speciality Hospital  to provide compassionate, comprehensive, and world-class healthcare solutions.

Experience the difference at Sea be Cure Multi Super Speciality Hospital , where your health and well-being are our priority. Welcome to a new era in healthcare excellence.




          </Text>
          {/* <Text  fontSize="15px"  mt="2">We are all in 2024!</Text>
          <Text  fontSize="15px"  mt="2">We are working on a MERN STACK PROJECT.</Text>
          <Text  fontSize="15px"  mt="2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores
            assumenda exercitationem accusamus sit repellendus quo optio dolorum
            corporis corrupti. Quas similique vel minima veniam tenetur
            obcaecati atque magni suscipit laboriosam! Veniam vitae minus nihil
            cupiditate natus provident. Ex illum quasi pariatur odit nisi
            voluptas illo qui ipsum mollitia. Libero, assumenda?
          </Text>
          <Text  fontSize="15px"  mt="2">Lorem ipsum dolor sit amet!</Text>
          <Text  fontSize="15px"  mt="2">Coding is fun!</Text> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Biography;
