import { Flex, Box,Grid, IconButton,Image, useDisclosure, Stack, Collapse, Text, Button, SimpleGrid, position } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useNavigate,Link } from "react-router-dom";
import './nav.css'
import { GiHamburgerMenu } from "react-icons/gi";
import { Menu, MenuButton, MenuList, MenuItem} from "@chakra-ui/react";
import {ChevronDownIcon} from '@chakra-ui/icons'
import logo from '../../assets/logo2-removebg-preview (1).png'
import { IoMdClose } from "react-icons/io";
import { FaFacebookF,FaInstagram,FaTwitter,FaYoutube,FaLinkedin } from "react-icons/fa";
import {useLocation} from 'react-router-dom'
//import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const [show, setShow] = useState(false);
    const location=useLocation();
     // Function to determine if a menu item is active
  const isActive = (path) => {
    return location.pathname === path;
  };

  // Function to apply active style to the menu item
  const getMenuItemStyle = (path) => {
    return isActive(path) ? { borderBottom: '1px solid teal', color: 'teal' } : {};
  };
  const { isOpen, onToggle } = useDisclosure();
   const navigate=useNavigate();
   

  return (
    <Flex
      align="center"
      
      justify="space-evenly"
      bg='#ebebeb'
      
      padding={{ base: "0.5rem 1rem", md: "1rem 2rem" }}
      borderBottom="1px"
      height='fit-content'
      width='full'
      gap={10}
      borderColor="gray.200"
    >
      <Box display='flex' p={4} gap={4} cursor='pointer' >
        <Image width='123px' height='49px' onClick={()=>navigate('/')} src={logo} alt='logo'/>
       
      </Box>
      {/* <Menu>
  <MenuButton
    as={IconButton}
    aria-label='Options'
    icon={<HamburgerIcon />}
    variant='outline'
  />
  <MenuList>
    <MenuItem  command='⌘T'>
      New Tab
    </MenuItem>
    <MenuItem  command='⌘N'>
      New Window
    </MenuItem>
    <MenuItem command='⌘⇧N'>
      Open Closed Tab
    </MenuItem>
    <MenuItem  command='⌘O'>
      Open File...
    </MenuItem>
  </MenuList>
</Menu> */}

      
        {/* <IconButton
          onClick={onToggle}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          variant="ghost"
          aria-label="Toggle navigation"
        /> */}
         <Box className="navbar-link"   > <Link className="nav_link" fontSize='md' to='/' style={getMenuItemStyle('/')}>HOME</Link>
        <Link className="nav_link" fontSize='md' to='/appoinment' style={getMenuItemStyle('/appoinment')}>APPOINTMENT</Link>
        <Link className="nav_link" fontSize='md' to='/aboutus' style={getMenuItemStyle('/aboutus')}>ABOUTUS</Link>
        <Link className="nav_link" fontSize='md' to='/contact' style={getMenuItemStyle('/contact')}>CONTACT</Link>
       </Box>
        
       
        
        {/* <Menu >
      <MenuButton className="nav_link" as="a"  rightIcon={<ChevronDownIcon />}  >
       
        MENU
        
       
      </MenuButton>
      <MenuList p={5} border='none' >
        <MenuItem className="menuItem" style={{cursor:"pointer", backgroundColor: "white", padding:"10px",width:"103px" ,color: "black",textAlign:"center",border:"none" }} >Booking</MenuItem>
        <MenuItem style={{cursor:"pointer", backgroundColor: "white", padding:"10px",width:"103px" ,color: "black",textAlign:"center",border:"none" }} onClick={()=>navigate('/team')}>Our Team</MenuItem>
        <MenuItem style={{cursor:"pointer", backgroundColor: "white", padding:"10px",width:"103px" ,color: "black",textAlign:"center",border:"none" }} onClick={()=>navigate('/testimonial')}>Testimonals</MenuItem>
      </MenuList>
    </Menu> */}

        
        
      <Box p={20} ml={20} className="button-btn">
        <Stack spacing={14} direction={[ 'column','row']}>
      <Button style={{background:'teal',padding:"1rem",borderRadius:'6px',border:'none',color:'white',height:'40px',cursor:'pointer'}} onClick={()=>navigate('/login')}>
    <Text fontSize='13px'>Login</Text>
  </Button>
  <Button ml={5} style={{background:'teal',padding:"1rem",borderRadius:'6px',border:'none',color:'white',height:'40px',cursor:'pointer'}} onClick={()=>navigate('/signup')}>
    <Text fontSize='13px'>Signup</Text>
  </Button>
  </Stack>
      </Box>

      <Box  p={20} className="navbar-sm">
        {/* Sidebar content */}
      { show ? <Stack spacing={14} direction={['row', 'column']} textAlign='center'  p={2} >
          {/* <FaFacebookF style={{color:'#FEA116',fontSize:"15px",cursor:"pointer"}}/>
          <FaTwitter style={{color:'#FEA116',fontSize:"15px",cursor:"pointer"}}/>
          <FaInstagram style={{color:'#FEA116',fontSize:"15px",cursor:"pointer"}}/>
          <FaLinkedin style={{color:'#FEA116',fontSize:"15px",cursor:"pointer"}}/>
          <FaYoutube style={{color:'#FEA116',fontSize:"15px",cursor:"pointer",marginRight:"60px"}}/> */}
          <Link className="nav_link2" fontSize='md' to='/'  style={getMenuItemStyle('/')}>HOME</Link>
        <Link className="nav_link2" fontSize='md' to='/appoinment'  style={getMenuItemStyle('/appoinment')}>APPOINTMENT</Link>
        <Link className="nav_link2" fontSize='md' to='/aboutus'  style={getMenuItemStyle('/aboutus')}>ABOUTUS</Link>
        <Link className="nav_link2" fontSize='md' to='/contact'  style={getMenuItemStyle('/contact')}>Contact</Link>
          <Button style={{background:'teal',padding:"1rem",borderRadius:'6px',border:'none',color:'white',height:'40px',cursor:'pointer'}}>
    <Text fontSize='13px'>Login</Text>
  </Button>
  <Button style={{background:'teal',padding:"1rem",borderRadius:'6px',border:'none',color:'white',height:'40px',cursor:'pointer'}}>
    <Text fontSize='13px'>Signup</Text>
  </Button>
          {/* Add more sidebar items as needed */}
        </Stack> :""}
      </Box>
      <Box  className="hamburger" onClick={() => setShow(!show)}>
          {show ? <IoMdClose/> : <GiHamburgerMenu/>}
        </Box>

      
    </Flex>
  );
};

export default Navbar;
