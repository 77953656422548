import React from 'react'
import Hero from '../components/pages/Hero'
import hero from '../assets/hero.png'
import Biography from '../components/pages/Biography'
import about from '../assets/about.png'
import Departments from '../components/pages/Departmnet'
import MessageForm from '../components/pages/MessageForm'
import Footer from '../components/pages/Footer'
import Carousel2 from './carousel'
import Doctor from '../components/pages/doctors'


const Home = () => {
  return (
    <>
    <Carousel2/>
      <Hero  title={
          "Welcome to Sea be Cure Multi Super Speciality Hospital  | Your Trusted Healthcare Provider"
        }
        imageUrl={hero}/>
        <Biography imageUrl={about} />
        <Doctor />
        <Departments/>
        {/* <MessageForm/> */}
        
    </>
  )
}

export default Home
