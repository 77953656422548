// Passing `columns={[2, null, 3]}` and `columns={{sm: 2, md: 3}}`
// will have the same effect.
import { Box, Button,Text,Image, Checkbox, FormControl, FormLabel,Heading, Input, Select, Textarea,SimpleGrid } from "@chakra-ui/react";
import { FaHospital } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";
import { FaBedPulse } from "react-icons/fa6";
import { FaHeartPulse } from "react-icons/fa6";
import { FaUserDoctor } from "react-icons/fa6";
import { MdBabyChangingStation } from "react-icons/md";
import { RiSurgicalMaskLine } from "react-icons/ri";
import { MdOutlinePsychology } from "react-icons/md";
const Doctor=()=>{
    //border='2px solid #d1f1ff' bg='#edfaff' color='#221e20'
    return(
        <Box w='full' p={14}>
            <Heading as="h2" color="black" mb={20} display='flex' justifyContent='center' alignItems='center'>
         <Image src='https://www.fortishealthcare.com/drupal-data/2023-02/health-checkups161_0.svg' mt={4}/> 
         <Text ml={4} fontSize='4xl'>Doctors</Text>
        </Heading>
        <SimpleGrid minChildWidth='400px' spacing='40px'>
  <Box border='2px solid #d1f1ff' className="doctor-card" bg='#edfaff' boxShadow='dark-lg'  p={8} fontFamily='Montserrat' height='fit-content' borderRadius='6px' color='#221e20'>
    <Text  fontSize='4xl' ><FaHospital mt={2} ml={2} color="2dc2e0" w='6' h='6'/>GENERAL MEDICINE</Text>
   <Text mb={2} fontSize='15px'>
   Dr.Supriyo Gope
 <br/>
 MBBS, MD (General Medicine)
 Specialist in Diabetes, Gastroenterology, Chest Medicine, Neuro Medicine.</Text> 
   {/* <Text fontSize='15px'>
    Dr. MINTU DHIBAR <br/>
   MBBS,DNB,GENERAL MEDICINE</Text>  */}
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaUserDoctor color="2dc2e0" w='6' h='6' mt={2} ml={2}/>UROLOGY</Text>
   <Text fontSize='15px'>
    Dr. PIYUSH AGARWAL <br/>
   MBBS,MS,MCH,UROLOGY</Text> 
   <Text fontSize='15px'>
    Dr. RANVEER KUMAR <br/>
   MBBS,MS,MCH UROLOGY</Text> 
  </Box>
  <Box bg='#edfaff' p={12} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='3xl' ><FaBedPulse color="2dc2e0" w='6' h='6' mt={2} ml={2}/>GENERAL/LAPAROSCOPIC SURGERY</Text>
   <Text mb={2} fontSize='15px'>
    Dr. P. K. SAHOO <br/>
   M.S,FIAGES (CONSLT. SURGEON)</Text> 
   <Text mb={2} fontSize='15px'>
    Dr. PALASH SAHA <br/>
   MBBS,MS</Text>
   <Text fontSize='15px'>
    Dr. S.N.JHA <br/>
   MBBS,MS,FAMS,GENERAL & LAPAROSCOPIC SURGEON</Text> 
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><MdBabyChangingStation color="2dc2e0" w='6' h='6' mt={2} ml={2}/>GYNAEOCOLOGY</Text>
   <Text fontSize='15px'>
   Dr. PUNAM DOKANIA <br/>
   MBBS,DGO</Text> 
   
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaHeartPulse color="2dc2e0" w='6' h='6' mt={2} ml={2}/>PAEDIATRIC</Text>
   <Text fontSize='15px'>
    Dr. ABRITA CHATTERJEE <br/>
   MBBS,MD (PAED)</Text> 
   
  </Box>
  <Box bg='#edfaff' p={8}   border='2px solid #d1f1ff'  height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><RiSurgicalMaskLine color="2dc2e0" w='6' h='6' mt={2} ml={2}/>NEPHROLOGY</Text>
   <Text fontSize='15px'>
   Dr. RAJIB MONDAL <br/>
   MBBS,MD,(DM NEPHROLOGY)</Text> 
   
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><RiSurgicalMaskLine color="2dc2e0" w='6' h='6' mt={2} ml={2}/>PLASTIC SURGERY</Text>
   <Text fontSize='15px'>
    Dr. SUBHAKANTA MOHAPATRA <br/>
   MBBS,MS,MCH</Text> 
   
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaUserDoctor color="2dc2e0" w='6' h='6' mt={2} ml={2}/>RADIOLOGY</Text>
   <Text mb={2} fontSize='15px'>
   
Dr.Nithin PM
 <br/>
 MBBS,DMRD</Text> 
   {/* <Text fontSize='15px'>
    Dr. MINTU DHIBAR <br/>
   MBBS,DNB,GENERAL MEDICINE</Text>  */}
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaHandHoldingHeart color="2dc2e0" w='6' h='6' mt={2} ml={2}/>ORTHOPAEDIC</Text>
   <Text mb={2} fontSize='15px'>
   Dr.Sutanu Goswami 
 <br/>
 MBBS, MS (ortho), DNB(ortho)
AO Fellowship in Spine Surgery (ISIC New Delhi)
Consultant Orthopaedic, Joint replacement and Spine Surgeon.</Text> 
   <Text fontSize='15px'>
    Dr. A.K. DOKANIA <br/>
   MBBS,ORTHOPAEDIC SURGEON</Text> 
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><MdOutlinePsychology color="2dc2e0" w='6' h='6' mt={2} ml={2}/>PHYSIOTHERAPIST</Text>
   <Text fontSize='15px'>
   Dr. AMIT BURNWAL PT  <br/>
   BPT(BU) MIAP FIMT MTCRP CSM</Text> 
   
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaHospital color="2dc2e0" w='6' h='6' mt={2} ml={2}/>ENT</Text>
   <Text fontSize='15px'>
    Dr. TANIA MUKHERJEE <br/>
   MBBS,MS(ENT),GOLD MEDALIST</Text> 
    
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaHeartPulse color="2dc2e0" w='6' h='6' mt={2} ml={2}/>CARDIOLOGY</Text>
    <Text fontSize='15px'>
    Dr.Anuraag Gupta 
 <br/>
 MBBS, MD(medicine),DM(Cardiology )</Text> 
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaUserDoctor color="2dc2e0" w='6' h='6' mt={2} ml={2}/>Oral & Dental Surgeon</Text>
    <Text fontSize='15px'>
    Dr. Pravin Kr. Choubey 
 <br/>
 BDS</Text> 
    
  </Box>
  <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><FaUserDoctor color="2dc2e0" w='6' h='6' mt={2} ml={2}/>Pain Clinic</Text>
    <Text fontSize='15px'>
    Dr. S.M.Arman 
 <br/>
 MBBS,MD,FIPM(Basic) Kolkata</Text> 
    
  </Box>
  {/* <Box bg='#edfaff' p={8} border='2px solid #d1f1ff' height='fit-content' borderRadius='6px' color='black'>
    <Text  fontSize='4xl' ><RiSurgicalMaskLine color="2dc2e0" w='6' h='6' mt={2} ml={2}/>PULMONOLOGY</Text>
    
  </Box> */}
  
</SimpleGrid>
        </Box>
    )
}

export default Doctor