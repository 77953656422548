import React from "react";
import vector from '../../assets/Vector.png'
import { Box, Container, Heading, Text, Image, Flex ,SimpleGrid} from "@chakra-ui/react";
import { transform } from "framer-motion";

const Hero = ({ title, imageUrl }) => {
  return (
    <Box bg="gray.100" py="20" px='140' display='flex' justifyContent='center' alignItems='center' >
      <Container maxW="container.xl">
        <SimpleGrid minChildWidth='300px' spacing={2} placeItems='center' p={10}>
          <Box maxW="full">
            <Heading as="h1" fontSize="xl" mb="4" textAlign='left'>
           {title}
            </Heading>
            <Text fontSize="sm" textAlign='left'>
              Sea be Cure Multi Super Speciality Hospital 
(a unit of Sea Global Medical Instruments Limited) Institute is a state-of-the<br/>-art facility dedicated
              to providing<br/> comprehensive healthcare services with<br/> compassion and
              expertise. Our team of<br/> skilled professionals is committed to<br/>
              delivering personalized care tailored to<br/> each patient's needs. At
              Sea Be Cure we<br/> prioritize your well-being, ensuring a harmonious<br/>
              journey towards optimal health and wellness.
            </Text>
          </Box>
          <Box maxW="full" >
            <Image src={imageUrl} alt="hero" className="animated-image"   />
            {/* <span>
            <img src={vector} alt="vector" style={{position:'absoltue',zIndex:'-1',top:'50%'}} />
          </span> */}
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Hero;
